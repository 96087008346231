import gql from 'graphql-tag'

export const GET_POINT = gql`
query {
    getPoint {
        empId
        companyName
        main
        point
    }
}

`
export const TRANSFER_POINT_CV = gql`
mutation (
    $empTransferId:ID
    $empReceiveId: ID
    $point: Int!
    $activityDate:Date
    $remark:String
) {
    transferCVPoint(
        info: {
            empTransferId:$empTransferId
            empReceiveId: $empReceiveId
            point: $point
            activityDate:$activityDate
            remark:$remark
        }
    )
}

`
export const TRANSFER_POINT_ALL = gql`
mutation (
    $empTransferId:ID
    $empReceiveId: ID
    $point: Int!
    $activityDate:Date
    $remark:String
) {
    transferPoint(
        info: {
            empTransferId:$empTransferId
            empReceiveId: $empReceiveId
            point: $point
            activityDate:$activityDate
            remark:$remark
        }
    )
}
`

export const TRANSFER_POINT = gql`
mutation (
    $empReceiveId: ID!
    $point: Int!
    $transferDate: Date
    $descriptions: String
) {
    transferPoint(
        info: {
            empReceiveId: $empReceiveId
            point: $point
            transferDate: $transferDate
            descriptions: $descriptions
        }
    )
}
`

export const WITHDRAW_POINT = gql`
mutation (
    $empId: ID!
    $point: Int!
    $withDrawDate: Date
    $descriptions: String
) {
    withdrawPoints(
        info: {
            empId: $empId
            point: $point
            withDrawDate: $withDrawDate
            descriptions: $descriptions
        }
    )
}
`
export const GET_ALLPOINT = gql`
query ($date1: Date, $date2: Date $type: String) {
    getAllTransfer(date1: $date1, date2: $date2 type:$type) {
        _id
        company_Transfer_Point_Name
        company_Receive_Point_Name
        Point
        transferDate
        descriptions
        transferer
    }
}
`