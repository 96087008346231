var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"box-tab"},[_c('p',{class:{ active: _vm.$route.query.pointType === 'cv' },on:{"click":function($event){return _vm.$router.push({ query: { pointType: 'cv' } })}}},[_vm._v(" CV Point ")]),_c('p',{class:{ active: _vm.$route.query.pointType === 'post' },on:{"click":function($event){return _vm.$router.push({ query: { pointType: 'post' } })}}},[_vm._v(" Post Points ")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"box"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Transfer From")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"From","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.transfer),expression:"transfer"}],staticClass:"input",class:{ error: errors[0] },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.transfer=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getCompany()}]}},_vm._l((_vm.companytrans),function(s,idx){return _c('option',{key:idx,domProps:{"value":s.empId}},[_vm._v(" "+_vm._s(s.companyName)+" ")])}),0)]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[(_vm.companytrans.length && _vm.$route.query.pointType === 'post')?_c('label',{staticClass:"label"},[_vm._v("Available Post Point: "),(_vm.companytrans.length)?_c('span',{staticClass:"available-point"},[_vm._v(_vm._s(_vm.transferPointM()))]):_vm._e()]):_vm._e(),(_vm.companytrans.length && _vm.$route.query.pointType === 'cv')?_c('label',{staticClass:"label"},[_vm._v("Available CV Point: "),(_vm.companytrans.length)?_c('span',{staticClass:"available-point"},[_vm._v(_vm._s(_vm.transferCVPoint()))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Point","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.point),expression:"point"}],staticClass:"input",attrs:{"type":"text","placeholder":"0","name":"rangeInput","min":"0","max":_vm.$route.query.p},domProps:{"value":(_vm.point)},on:{"input":function($event){if($event.target.composing){ return; }_vm.point=$event.target.value}}}),_c('span',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Transfer To")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"To","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.receive),expression:"receive"}],staticClass:"input",class:{ error: errors[0] },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.receive=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.company),function(sl,idx){return _c('option',{key:idx,domProps:{"value":sl.empId}},[_vm._v(" "+_vm._s(sl.companyName)+" ")])}),0)]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Remark")]),_c('div',{staticClass:"control"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.remark),expression:"remark"}],staticClass:"w textarea",attrs:{"name":"","id":"","cols":"100","rows":"3"},domProps:{"value":(_vm.remark)},on:{"input":function($event){if($event.target.composing){ return; }_vm.remark=$event.target.value}}})])]),(_vm.errorMessage)?_c('div',{staticClass:"box-error"},[_c('div',{staticClass:"error_text"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"button primary",on:{"click":function($event){return handleSubmit(_vm.transferPoint)}}},[_vm._v(" Submit ")])])])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-header"},[_c('h3',[_vm._v("Points Transfer")])])}]

export { render, staticRenderFns }