<template>
  <section class="section">
    <div class="container">
      <div class="page-header">
        <h3>Points Transfer</h3>
      </div>
      <div class="box-tab">
        <p
          @click="$router.push({ query: { pointType: 'cv' } })"
          :class="{ active: $route.query.pointType === 'cv' }"
        >
          CV Point
        </p>
        <p
          @click="$router.push({ query: { pointType: 'post' } })"
          :class="{ active: $route.query.pointType === 'post' }"
        >
          Post Points
        </p>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="box">
          <div class="field">
            <label for="" class="label">Transfer From</label>
            <div class="control">
              <ValidationProvider
                name="From"
                rules="required"
                v-slot="{ errors }"
              >
                <select
                  v-model="transfer"
                  @change="getCompany()"
                  class="input"
                  :class="{ error: errors[0] }"
                >
                  <option
                    v-for="(s, idx) in companytrans"
                    :key="idx"
                    :value="s.empId"
                  >
                    {{ s.companyName }}
                  </option>
                </select>
              </ValidationProvider>
            </div>
          </div>
          <div class="field">
            <label
              class="label"
              v-if="companytrans.length && $route.query.pointType === 'post'"
              >Available Post Point:
              <span class="available-point" v-if="companytrans.length">{{
                transferPointM()
              }}</span>
            </label>
            <label
              class="label"
              v-if="companytrans.length && $route.query.pointType === 'cv'"
              >Available CV Point:
              <span class="available-point" v-if="companytrans.length">{{
                transferCVPoint()
              }}</span></label
            >
            <div class="control">
              <ValidationProvider
                name="Point"
                rules="required|integer"
                v-slot="{ errors }"
              >
                <input
                  class="input"
                  type="text"
                  placeholder="0"
                  name="rangeInput"
                  min="0"
                  :max="$route.query.p"
                  v-model="point"
                />
                <span style="color: red; font-size: 12px">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="field">
            <label for="" class="label">Transfer To</label>
            <div class="control">
              <ValidationProvider
                name="To"
                rules="required"
                v-slot="{ errors }"
              >
                <select
                  v-model="receive"
                  class="input"
                  :class="{ error: errors[0] }"
                >
                  <option
                    v-for="(sl, idx) in company"
                    :key="idx"
                    :value="sl.empId"
                  >
                    {{ sl.companyName }}
                  </option>
                </select>
              </ValidationProvider>
            </div>
          </div>
          <div class="field">
            <label for="" class="label">Remark</label>
            <div class="control">
              <textarea
                v-model="remark"
                name=""
                id=""
                cols="100"
                rows="3"
                class="w textarea"
              ></textarea>
            </div>
          </div>
          <div v-if="errorMessage" class="box-error">
            <div class="error_text">{{ errorMessage }}</div>
          </div>
          <div class="button-group">
            <button @click="handleSubmit(transferPoint)" class="button primary">
              Submit
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import { GET_COMPANY } from "@/graphql/Company";
import { TRANSFER_POINT_ALL, TRANSFER_POINT_CV } from "@/graphql/Point";
import apolloClient from "@/services/apollo-client";
import apolloClient_Company from "@/services/apollo-client-company";
export default {
  data: () => ({
    companytrans: [],
    transfer: "",
    receive: "",
    point: "",
    remark: "",
    num: "",
    company: [],
    errorMessage: "",
    errorMessagereset: "",
    errorfileSize: "",
    getPoints: {
      empId: "",
      point: null,
    },
  }),
  computed: {
    transferPointM() {
      return () => {
        if (!this.companytrans.length) return;
        const points = this.companytrans.find((i) => i.empId === this.transfer);
        return points ? points.point : 0;
      };
    },
    transferCVPoint() {
      return () => {
        if (!this.companytrans.length) return;
        const cvPoints = this.companytrans.find(
          (i) => i.empId === this.transfer
        );
        return cvPoints ? cvPoints.cvPoints : 0;
      };
    },
  },
  methods: {
    async Companytrans() {
      const res = await apolloClient.query({
        query: GET_COMPANY,
      });
      this.companytrans = res.data.selectEMP;
    },

    async getCompany() {
      const res = await apolloClient.query({
        query: GET_COMPANY,
      });

      this.company = res.data.selectEMP;
      var index = this.company.findIndex((o) => {
        return o.empId === this.transfer;
      });
      if (index !== -1) this.company.splice(index, 1);
    },

    async transferPoint() {
      this.errorMessage = "";
      try {
        const isConfirmed = await this.$dialog.confirm();
        if (!isConfirmed) return;
        if (this.$route.query.pointType == "post") {
          await apolloClient_Company.mutate({
            mutation: TRANSFER_POINT_ALL,
            variables: {
              empTransferId: this.transfer,
              empReceiveId: this.receive,
              point: parseInt(this.point),
              activityDate: new Date(),
              remark: this.remark,
            },
          });
        } else {
          await apolloClient_Company.mutate({
            mutation: TRANSFER_POINT_CV,
            variables: {
              empTransferId: this.transfer,
              empReceiveId: this.receive,
              point: parseInt(this.point),
              activityDate: new Date(),
              remark: this.remark,
            },
          });
        }
        this.$router.push({
          query: { pointType: this.$route.query.pointType },
        });
        this.remark = "";
        this.transfer = "";
        this.receive = "";
        this.errorMessage = "";
        window.location.reload();
      } catch ({ graphQLErrors }) {
        this.errorMessage = graphQLErrors[0].message;
      }
    },
  },

  watch: {
    "$route.query": {
      handler(val) {},
      immediate: true,
      deep: true,
    },
  },
  async created() {
    await this.Companytrans();
    await this.getCompany();

    if (!this.$route.query.pointType) {
      this.$router.push({ query: { pointType: "cv" } });
    }
  },
};
</script>

<style lang="scss" scoped>
.error_text {
  color: red;
}
.available-point {
  color: var(--primary-color);
}
.box-tab {
  display: flex;
  align-items: center;
  gap: 5px;
  p {
    padding: 10px 15px;
    background-color: var(--grey-color);
    font-size: var(--sm-font);
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    position: relative;
    line-height: 1;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(
        to top,
        rgba(#000, 0.05),
        rgba(0, 0, 0, 0)
      );
      height: 10px;
      z-index: 8;
    }
    &.active {
      background-color: var(--primary-color) !important;
      color: #fff;
      z-index: 9;
      &::after {
        content: none !important;
      }
    }
  }
}
.box {
  z-index: 8;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid var(--border-color);
  .box-tag {
    position: absolute;
    top: -32px;
    left: -1px;
    font-size: 14px;
    background: var(--grey-color);
    padding: 5px 10px;
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: 5px 5px 0 0;
    z-index: 0;
  }
}
.hline {
  margin-bottom: 50px;
}
</style>
